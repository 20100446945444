@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import './variables.scss';

* {
  font-family: 'Noto Sans', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#app {
  display: flex;
  flex-direction: row;
  background: rgb(135, 92, 255);
  background: linear-gradient(90deg, rgba(135, 92, 255, 0.1) 0%, rgba(250, 134, 46, 0.1) 100%);
  color: $dark_grey;


  &.dark {
    background: $dark_grey !important;
  }
}

.dark {
  background-color: $light_grey !important;

  & * {
    color: $white;
  }
}

.light {
  background-color: $white !important;

  &.navbar-item-link {
    background-color: transparent !important;

    &:hover {
      background-color: rgba($light_grey, 0.1) !important;
    }
  }

  & * {
    color: $dark_grey;
  }
}

.red {
  color: $red;
}

.mantine-DateTimePicker-root {
  padding: 0;
  border: none;
  outline: none;
  margin: 0;
}