@import '../globals.scss';

#auth,
#authorize {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding-left: 10vw;
  position: relative;
  justify-content: center;
  z-index: 1000 !important;

  .quick-menu-item {
    border: none;
    outline: none;
    background-color: $white;
    width: 5vh;
    height: 5vh;
    cursor: pointer;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 50%;

    &.dark {
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.8);
    }
  }

  &.dark {
    input {
      background-color: $dark_grey;
    }
  }

  .header {
    h1 {
      font-size: 3.2rem;
      margin-bottom: 5vh;
    }
  }

  .discord-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 5vh;
    border: none;
    outline: none;
    background-color: #5865F2;
    color: $white;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin-bottom: 5vh;
  }

  .form-container {
    width: 50%;

    p {
      margin-bottom: 10px;
      font-weight: 600;
    }

    input {
      border: none;
      outline: none;
      height: 5vh;
      width: 60%;
      border-radius: 20px;
      padding-left: 15px;
      -moz-appearance: textfield;
      font-size: 1.2rem;


      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .form-submit-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-top: 2vh;
      width: 60%;


      .submit-button {
        background: linear-gradient(90deg, rgba(135, 92, 255, 1) 0%, rgba(250, 134, 46, 1) 100%);
        border: none;
        border-radius: 20px;
        padding: 12px 30px 12px 30px;
        color: $white;
        font-size: 1rem;
        margin-left: 2vw;
        cursor: pointer;
      }
    }

  }
}