@import '../globals.scss';

.detailEvent {
  border-radius: 20px;
  padding: 20px;
  height: auto;
  margin-bottom: 2vh;
  width: 95%;


  &.shadow {
    box-shadow: 0 0 10px 0 rgba(#000, .8);
  }

  .event-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .event-title {
      font-size: 1.5rem;
      font-weight: 600;
    }

    .buttons-container {
      display: flex;
      flex-direction: row;

      .event-add-to-calendar-button {
        padding: 0 10px 0 10px;
        background: $gradiant;
        border: none;
        outline: none;
        border-radius: 10px;
        cursor: pointer;
        margin-left: 10px;
        color: $white;

        &.disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }
    }

  }

  .event-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // height: 60%;
    // background-color: $purple;

    .event-body-column {
      // background-color: $orange;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 50%;
    }

    .event-body-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 20px;

      .participants-pp-container{
        display: flex;
        flex-direction: row;
        
        img{
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
    }
  }
}