@import '../globals.scss';

#planning {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding-left: 50px;
  padding-right: 50px;
  position: relative;
  padding-top: 10vh;

  .planning-viewer {
    display: flex;
    flex-direction: row;

    .calendar-container {
      margin-top: 5vh;
      margin-left: 20px;
      background-color: transparent !important;

      &.dark {
        background-color: $dark_grey !important;

        .mantine-DatePicker-monthLevel {
          border-radius: 20px;
          padding: 20px;
          background-color: $light_grey !important;
        }
      }

      .mantine-DatePicker-monthLevel {
        background-color: $white;
        border-radius: 20px;
        padding: 20px;
      }
    }

    .planning-detail-container {
      margin-left: 50px;
      width: 100%;
      height: 70vh;
      margin-top: 5vh;
      overflow: auto;

      .no-detail {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
    }

    .dashboard-container {
      // background-color: $orange;
      margin-left: 20px;
      height: 30vh;
      overflow: auto;
      margin-top: 3vh;
      display: flex;
      flex-direction: column;
      padding: 20px;
      border-radius: 20px;

      .title{
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 20px;
      }

      .stats-container{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;

        .stat-box{
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .stat-title-container{
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }
      }
    }
  }
}