@import '../globals.scss';

#parallaxContainer {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -1;

  .parallax-creeper {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    width: 300px;
    top: 0vh;
    right: 40vw;
  }

  .parallax-mic {
    transform: rotate(15deg);
    width: 300px;
    right: 15vw;
  }

  .parallax-coffee {
    transform: rotate(-10deg);
    width: 200px;
    top: 45vh;
    right: 30vw;
  }

  .parallax-gamepad {
    width: 200px;
    top: 55vh;
    right: 10vw;
  }

  .parallax-layer {
    position: absolute;
  }

}