@import '../globals.scss';

.navbar {
  height: 100vh;
  width: 300px;
  // background-color: $dark_grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .navbar-user-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    img {
      border-radius: 50%;
    }

    p {
      // color: $dark_grey;
      font-size: 1.2rem;
      margin-top: 10px;
    }
  }

  .items-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .navbar-item {
      display: flex;
      flex-direction: column;
      width: 100%;
      text-decoration: none;


      .navbar-item-link {
        // color: $dark_grey;
        font-size: 1.2rem;
        font-weight: 500;
        text-decoration: none;
        padding: 10px 30px 10px 30px;
        border-radius: 10px;
        margin-top: 20px;

        span {
          margin-right: 10px;
        }

        &:hover {
          background-color: rgba($light_grey, 0.1);
        }

        &.dark {
          background-color: $dark_grey !important;

          &:hover {
            background-color: $light_grey !important;
          }
        }
      }
    }
  }
}

.navbar-mobile {
  position: absolute;
  bottom: 0;
  // background-color: $dark_grey;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  .navbar-mobile-item {
    .navbar-mobile-item-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      text-decoration: none;
      color: $white;
    }
  }
}