@import "../globals.scss";

.quick-menu {
  position: absolute;
  top: 0;
  right: 5vw;
  background-color: $white;
  height: 7vh;
  width: 15vw;
  border-radius: 0 0 20px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  z-index: 1000;

  .quick-menu-item {
    border: none;
    outline: none;
    background-color: $white;
    width: 5vh;
    height: 5vh;
    cursor: pointer;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 50%;

    &.dark {
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.8);
    }
  }
}