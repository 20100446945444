@import '../globals.scss';

#notFound {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding-left: 10vw;
  position: relative;
  justify-content: center;
  z-index: 1000 !important;

  .title {
    font-size: 10rem;
    font-weight: 800;
  }

  .subtitle {
    font-size: 2rem;
    margin-bottom: 5vh;
  }
}