@import '../globals.scss';

#servers {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding-left: 50px;
  padding-right: 50px;
  position: relative;
  padding-top: 10vh;
}